@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
:root {
    --primary-color: #148a7c;
}

h3 {
    margin: 0;
    font-size: 18px;
}

h1 {
    font-size: 50px;
    font-weight: 400;
    color: black;
    font-family: 'Playfair Display', serif;
}

h2 {
    font-size: 20px;
    color: black;
}


main {
    display: grid;
    grid-template-columns: auto 370px;
    gap: 20px;
    padding: 20px;
    max-width: 1700px;
    margin: auto;

}
#aussteller-cards {
display: grid;
gap: 20px;
justify-content: center;

}
#werbungen {
    display: grid;
    gap: 20px;
    justify-content: center;
    
    }
#sidebar {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
}

.toggle {
    cursor: pointer;
}
@media (max-width: 800px) {
    #sidebar {
        display: none;
    }
    main{
        grid-template-columns: auto;
    }
    
}

body {
    background-color: #d6d6d6;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
}
.loading-image {
    height: 150px;
    width: 150px;
}
.no-results {
    text-align: center;

}
.loading {
    width: 100%;
    object-fit: contain;
    text-align: center;
    padding-bottom: 30px;
}

header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 20px;
}

.time-link{
    align-self: end;
}
.pers2{
    margin-top: -5px;
}

.article img {
    max-height: 80px;
    max-width: 175px;
    object-fit: contain;
}

.article {
    background-color: white;
    max-width: 80vw;
    box-shadow: 0 5px 10px rgb(100, 100, 100);
    border-radius: 10px;
    padding: 15px 15px 5px 15px;
    border-left: 10px solid;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: start;
    transition-timing-function: ease-in;
    transition-delay: 1s;
    min-height: 180px;
    justify-content: space-between;

}


.card_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.card_bottom img {
    height: 30px;
}

.card_middle {
    display: flex;
    justify-content: space-between;
}

.label_box {
    display: flex;
    flex-direction: row;
    gap: 3px;
    flex-wrap: wrap;
}

.icon_text >a{
    text-decoration: none;
    color: black;

}
.icon_text >a >p{
    white-space: nowrap;
}

.tag {
    background-color: grey;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
}

.card_bottom {
    display: flex;
    justify-content: space-between;
}
.filter {
    display: flex;
    gap: 0.5rem;
    max-width: 500px;
    width: 100%;
    flex-wrap: wrap;

}
.filter-col{
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: end;

}
.filter-col label{
    padding-bottom: 0.5rem;
}

#clean {
    flex-basis: 40px;
}
#suche {
    flex-grow: 3;
}
#clean input{
    background-color: #000000;
    border: 0px;

}
#delete-button{
    height: 20px;
    width: 20px;
    padding: 10px;
    margin-bottom: 1px;
}



.icon_text {
    display: flex;
    align-items: center;
    gap: 5px;
}
.card_top_right {
    display: flex;
    align-items: top;
    gap: 10px;

}
.card_top_right > h4{
    margin: 0px 0px;
}

.icon_text img {
    max-height: 20px;
}

.icon_text p {
    font-weight: 500;
}

.card_detail {
    display: none;
}

input, select{
    border: 2px solid black;
    background-color: transparent;
    color: black;
    border-radius: 10px;
    height: 36px;
    padding-left: 1rem;
    text-align: left;
}
select {
    height: 42px;
    max-width: 100px;
}
option{
    background-color: #d6d6d6;
}

.ins-img {
    max-width: 100%;
    box-shadow: 0 3px 10px rgb(100, 100, 100);
    max-width: 100%;
}


.card_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.card_content_top {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;

}
.mobile{
    display: flex;
    justify-content: center;
}
footer {
    background: white;
    padding: 3rem;
    text-align: center;
    margin-top: 2rem;

}
footer >a {
    color: black;
}

.footer_logos{
    display: grid;
    grid-template-columns: 
    repeat(auto-fit, 200px);
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 2rem;
}
.footer_logos_medien{
    display: grid;
    grid-template-columns: 
    repeat(auto-fit, 200px);
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    
}
.footer_logos>img, .footer_logos_medien>img{
object-fit: contain;
width: 100%;
justify-self: center;
}


@media (min-width: 740px) {
    #aussteller-cards {
        display: grid;
        grid-template-columns: 1fr;
  align-content: start;
    }
    
    .mobile{
        display: none !important;
    }
}

@media (min-width: 1100px) {
    #aussteller-cards {
        grid-template-columns: 1fr 1fr;
        align-content: start;
    }
}

@media (min-width: 1450px) {
    #aussteller-cards {
        grid-template-columns: 1fr 1fr 1fr;
        align-content: start;
    }
}